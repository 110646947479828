import * as React from 'react';

import SEO from 'components/shared/SEO';

import { PrivacyPolicy } from 'components/PrivacyPolicy/PrivacyPolicy';

const CookiesPolicyPage = ({ pageContext }) => {
  return (
    <>
      <SEO
        title="Polityka cookies - Grzybowska 37"
        ogTitle="Polityka cookies - Grzybowska 37"
      />
      <PrivacyPolicy
        heading={pageContext.heading}
        content={pageContext.content}
        isCookiesPolicy
      />
    </>
  );
};

export default CookiesPolicyPage;
